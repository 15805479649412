import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'

import Opportunities from './components/Opportunities/Opportunities'
import Collateral from './components/Collateral/Collateral'
import Customers from './components/Customers/Customers'
import CustomerDetail from './components/Customers/CustomerDetail'
// import './index.css'
import './styles/globals.css'
import { useAppContext } from '../src/AppContext'
import Home from './components/Home/Home'
import Sidebar from './components/Sidebar/Sidebar'
import Login from './components/Login/Login'
import Invoices from './components/Invoices/Invoices'
import Support from './components/Support/Support'

const auth = getAuth()

const App: React.FC = () => {
  const [user, loading] = useAuthState(auth)
  const { authorized, currentUser } = useAppContext()

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <Router>
      <div className="flex">
        {currentUser && authorized && <Sidebar />}
        <div className="flex-grow">
          <Routes>
            <Route
              path="/"
              element={
                currentUser && authorized ? (
                  <Home />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route path="/login" element={<Login />} />
            {currentUser && authorized && (
              <>
                <Route path="/opportunities" element={<Opportunities />} />
                <Route path="/collateral" element={<Collateral />} />
                <Route path="/customers" element={<Customers />} />
                <Route
                  path="/customers/:customerId"
                  element={<CustomerDetail />}
                />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/support" element={<Support />} />
              </>
            )}
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </div>
      </div>
    </Router>
  )
}

export default App
