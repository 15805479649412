import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  fetchCustomerDetails,
  fetchCustomerStatistics,
  fetchActiveUsers,
  deleteCustomer,
  resetPassword,
} from '../../app.service'
import { useAppContext } from '../../AppContext'
import { DateTime } from 'luxon'

import { Bar, BarChart, CartesianGrid, Cell, XAxis } from "recharts"

 
import { ChartConfig, ChartContainer } from "../ui/chart"

const CustomerDetail: React.FC = () => {
  const { customerId } = useParams<{ customerId: string }>()
  const [customerDetails, setCustomerDetails] = useState<any>(null)
  const [customerStatistics, setCustomerStatistics] = useState<any[]>([])
  const [activeUsers, setActiveUsers] = useState<any[]>([])
  const [deleteDate, setDeleteDate] = useState<string>('')
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false)
  const [selectedStatistics, setSelectedStatistics] = useState<any>(null)
  const { currentToken, currentCustomer } = useAppContext()
  const navigate = useNavigate()
  useEffect(() => {
    const fetchDetails = async () => {
      if (currentToken && currentCustomer && customerId) {
        const partnerCustomerId = currentCustomer.customerId

        // Fetch customer details
        const customerResult = await fetchCustomerDetails(
          currentToken,
          partnerCustomerId,
          parseInt(customerId),
        )
        if (customerResult.success) {
          setCustomerDetails(customerResult.data)
        }

        // Fetch customer statistics
        const statsResult = await fetchCustomerStatistics(
          currentToken,
          partnerCustomerId,
          parseInt(customerId),
        )
        if (statsResult.success) {
          setCustomerStatistics(statsResult.data)
        }

        // Fetch active users
        const usersResult = await fetchActiveUsers(
          currentToken,
          partnerCustomerId,
          parseInt(customerId),
        )
        if (usersResult.success) {
          setActiveUsers(usersResult.data)
        }
      }
    }

    fetchDetails()
  }, [currentToken, currentCustomer, customerId])

  const handleDeleteCustomer = async () => {
    if (currentToken && currentCustomer && customerId) {
      const partnerCustomerId = currentCustomer.customerId
      const result = await deleteCustomer(
        currentToken,
        partnerCustomerId,
        parseInt(customerId),
        deleteDate,
      )
      if (result.success) {
        navigate('/customers')
      } else {
        console.error('Error deleting customer:', result.message)
      }
    }
  }

  const handleResetPassword = async (userId: number) => {
    if (currentToken && currentCustomer) {
      const partnerCustomerId = currentCustomer.customerId
      const result = await resetPassword(
        currentToken,
        partnerCustomerId,
        userId,
      )
      if (result.success) {
        alert('Password reset successfully.')
      } else {
        console.error('Error resetting password:', result.message)
      }
    }
  }

  const handleStatisticsClick = (statistics: any) => {
    setSelectedStatistics(statistics)
    setShowUserDetails(true)
  }




  
   
  const chartConfig = {
    totUsers: {
      color: "#2563eb",
    },
    active: {
      color: "#00000",
    },
    loggenIn: {
      color: "255.1 91.7% 76.3%",
    },

  } satisfies ChartConfig

  return (
    <div className="flex flex-col h-screen p-4">
      {customerDetails && (
        <>
          <div className="flex justify-between items-center">
            <div className="flex gap-4">
              <div className="flex items-center justify-center size-24 border rounded-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-16 stroke-blue-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
                  />
                </svg>
              </div>
              <div>
                <h2 className="text-2xl font-bold ">
                  {customerDetails.customerName}
                </h2>
                <p className="text-sm text-slate-600">
                  <strong>Organisational Number:</strong>
                  {customerDetails.organisationalNumber}
                </p>
                <p className="text-sm text-slate-600">
                  <strong>Partner Organization ID:</strong>{' '}
                  {customerDetails.partnerOrganizationId}
                </p>
                <p className="text-sm text-slate-600">
                  <strong>Created: </strong>
                  {customerDetails.created}
                </p>
              </div>
            </div>

            {/* Delete Customer Section */}
            <div className="bg-rose-50 px-5 py-4 rounded-lg">
              <h3 className="text-lg font-bold">Delete Customer</h3>
              <p className="text-red-500 text-xs">
                Warning: Deleting this customer will revoke their access to the
                <br />
                Intelly Platform on the selected date.
              </p>
              <div className="flex gap-4">
                <input
                  type="date"
                  value={deleteDate}
                  onChange={(e) => setDeleteDate(e.target.value)}
                  className="mt-2 px-2 h-8 border w-72 rounded-lg"
                />
                <button
                  className="bg-red-500 text-white px-4 h-8 rounded-lg mt-2 flex gap-2 items-center text-sm"
                  onClick={() => setShowDeleteModal(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
                    />
                  </svg>
                  Schedule Deletion
                </button>
              </div>
            </div>
          </div>
          {/* Active Users and Customer Statistics Section in Columns */}
          <div className="flex-1 flex mt-8 space-x-4 mb-4">
            {/* Active Users Section */}
            <div className="flex-1 border rounded-lg p-4 flex flex-col">
              <h3 className="text-xl font-bold mb-4">Active Users</h3>
              <div className="overflow-auto">
                {activeUsers.length > 0 ? (
                  <ul className="">
                    {activeUsers.map((user) => (
                      <li
                        key={user.userId}
                        className="mb-2 flex justify-between items-center hover:bg-blue-50 py-1 px-2 rounded-lg"
                      >
                        <span className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6 stroke-blue-500"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                          <div className="grid grid-flow-col gap-4 divide-x">
                            <span className="px-4 min-w-44 text-sm font-semibold text-slate-900">
                              {user.userName}
                            </span>
                            <span className="px-4 min-w-52 text-sm text-slate-500">
                              {user.email}
                            </span>
                            <span className="px-4 text-sm text-slate-500">
                              (ID: {user.userId})
                            </span>
                          </div>
                        </span>
                        <button
                          className="border text-blue-500 border-blue-500 text-xs px-2 py-1 ml-2 rounded-lg bg-white"
                          onClick={() => handleResetPassword(user.userId)}
                        >
                          Reset Password
                        </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No active users available</p>
                )}
              </div>
            </div>

            {/* Customer Statistics Section */}
            <div className="flex-1 border rounded-lg p-4 flex flex-col">
              <h3 className="text-xl font-bold mb-4">Customer Statistics</h3>
              <div className="overflow-auto">
           

                {customerStatistics.length > 0 ? (
                  <div>
                    {customerStatistics.map((stat) => {
                      let date

                      if (stat?.date.includes('T')) {
               
                        date = DateTime.fromISO(stat?.date)
                      } else {
              
                        date = DateTime.fromFormat(
                          stat?.date,
                          'yyyy-MM-dd HH:mm:ss',
                        )
                      }

                      const monthDay = date.toFormat('LLL dd') 
                      const year = date.toFormat('yyyy') 

  const chartData = [
    { users: "Users", value: stat.numberOfUsers, color: "#60a5fa" }, 
    { users: "Active", value: stat.numberOfActiveUsers, color: "#6ee7b7" }, 
    { users: "Logged in", value: stat.numberOfLoggedInUsers, color: "#a78bfa" }, 
  ];

                      return (
                        <div
                          key={stat.customerStatisticsId}
                          className="border-b p-2 mb-2 cursor-pointer hover:bg-gray-100 flex gap-8 items-center h-24"
                          onClick={() => handleStatisticsClick(stat)}
                        > 
                        <div className='flex justify-between w-full'>
                          <div className='flex gap-4 items-center'>
                          <div className="flex flex-col font-medium bg-blue-100 text-slate-700 size-20 rounded-lg items-center justify-center">
                            <div className="">{monthDay}</div>
                            <div className="">{year}</div>
                          </div>

                          <div className="text-sm">
                            <span className="flex">
                              <p className="w-32 font-light">Users: </p>
                              <p className="font-semibold text-blue-500">
                                {stat.numberOfUsers}
                              </p>
                            </span>
                            <span className="flex">
                              <p className="w-32 font-light">Active Users:</p>
                              <p className="font-semibold text-emerald-400">
                                {stat.numberOfActiveUsers}
                              </p>
                            </span>
                            <span className="flex">
                              <p className="w-32 font-light">Logged In Users:</p>
                              <p className="font-semibold text-violet-400">
                                {stat.numberOfLoggedInUsers}
                              </p>
                            </span>
                          </div>
                          </div>
                     
                          <div className='self-end h-full flex items-end'>

                          <ChartContainer config={chartConfig} className="h-12 w-24">
  <BarChart accessibilityLayer data={chartData}>
    
  
    <Bar
      dataKey="value" 
      radius={4} 
    >
      {chartData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={entry.color} /> 
      ))}
    </Bar>
  </BarChart>
</ChartContainer></div>
                          </div>
                        </div>
                      )
                    })}
      
                  </div>
                ) : (
                  <p>No statistics available</p>
                )}
              </div>
            </div>
          </div>
          <button
            className="bg-gray-300 text-black px-4 py-2 rounded mb-4 self-start w-32"
            onClick={() => navigate('/customers')}
          >
            Go back
          </button>

          {/* User Details Modal */}
          {showUserDetails && selectedStatistics && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
              <div className="bg-white p-6 rounded-lg shadow-lg w-[900px] h-[90vh] flex flex-col">
                <h2 className="text-xl font-bold mb-4">
                  User Details for {selectedStatistics.date}
                </h2>
                <div className="flex space-x-4 flex-1 overflow-hidden">
                  {/* Users Section */}
                  <div className="flex-1 border rounded p-4 flex flex-col overflow-hidden">
                    <h3 className="text-lg font-bold mb-2">Users</h3>
                    <div className="flex-1 overflow-auto">
                      <ul className="list-disc pl-4">
                        {selectedStatistics.users.map((user: any) => (
                          <li key={user.userId}>ID: {user.userId}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {/* Active Users Section */}
                  <div className="flex-1 border rounded p-4 flex flex-col overflow-hidden">
                    <h3 className="text-lg font-bold mb-2">Active Users</h3>
                    <div className="flex-1 overflow-auto">
                      <ul className="list-disc pl-4">
                        {selectedStatistics.activeUsers.map((user: any) => (
                          <li key={user.userId}>ID: {user.userId}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {/* Logged In Users Section */}
                  <div className="flex-1 border rounded p-4 flex flex-col overflow-hidden">
                    <h3 className="text-lg font-bold mb-2">Logged In Users</h3>
                    <div className="flex-1 overflow-auto">
                      <ul className="list-disc pl-4">
                        {selectedStatistics.loggedInUsers.map((user: any) => (
                          <li key={user.userId}>ID: {user.userId}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-4">
                  <button
                    className="bg-gray-400 text-white px-4 py-2 rounded"
                    onClick={() => setShowUserDetails(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Delete Confirmation Modal */}
          {showDeleteModal && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
              <div className="bg-white p-6 rounded shadow-lg">
                <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
                <p>
                  Are you sure you want to delete this customer? Access to the
                  Intelly Platform will be revoked on {deleteDate}.
                </p>
                <div className="flex justify-end mt-4">
                  <button
                    className="bg-gray-400 text-white px-4 py-2 rounded mr-2"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded"
                    onClick={handleDeleteCustomer}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default CustomerDetail
