import axios from 'axios';

const BASEURL = process.env.REACT_APP_API_URL;

// Function to get the initial user by email
export async function getUserByEmail(email: string, userToken: string): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/users/user`, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: {
        email: email,
      },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to get partner customer data (original function)
export async function getPartnerCustomer(customerId: number, userToken: string): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/customers/customer`, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: {
        customerId: encodeURIComponent(customerId),
      },
    });
    return response.data.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// New function to get customer details using path parameter and query parameter for partnerCustomerId
export async function fetchCustomerDetails(userToken: string, partnerCustomerId: number, customerId: number): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/partner/customers/${customerId}`, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: {
        partnerCustomerId,
      },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to fetch opportunities (no customerId required)
export async function getOpportunities(userToken: string, partnerCustomerId: number): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/partner/opportunities`, {
      headers: { Authorization: userToken },
      params: { partnerCustomerId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to get accounts (no customerId required)
export async function getAccounts(userToken: string, partnerCustomerId: number): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/partner/accounts`, {
      headers: { Authorization: userToken },
      params: { partnerCustomerId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to add a new opportunity (no customerId required)
export async function createOpportunity(
  data: {
    AccountId: string | null;
    AddNewAccount: boolean;
    Account: { Name: string; Organisational_Number__c: string } | null;
    CloseDate: string;
    Amount_of_Users__c: number;
    Modules__c: string;
  },
  userToken: string,
  partnerCustomerId: number
): Promise<any> {
  try {
    const response = await axios.post(`${BASEURL}/partner/opportunities`, data, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: { partnerCustomerId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to update an opportunity (no customerId required)
export async function updateOpportunity(
  id: string,
  data: {
    StageName?: string;
    CloseDate?: string;
    Amount_of_Users__c?: number;
    Modules__c?: string[];
  },
  userToken: string,
  partnerCustomerId: number
): Promise<any> {
  try {
    const response = await axios.patch(`${BASEURL}/partner/opportunities/${id}`, data, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: { partnerCustomerId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to add a new customer
export async function addCustomer(
  customerData: {
    customerName: string;
    organisationalNumber: string;
    partnerOrganizationId: string;
    minAmountOfUsers: number;
    startDate: string;
    clientCredentials: {
      clientId: string;
      clientSecret: string;
      apiUrl: string;
      accessTokenUrl: string;
    };
    administrators: Array<{
      email: string;
      userName: string;
      integrationUserId: string;
    }>;
  },
  userToken: string,
  partnerCustomerId: number
): Promise<any> {
  try {
    const response = await axios.post(`${BASEURL}/partner/customers`, customerData, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: {
        partnerCustomerId,
      },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to delete a customer
export async function deleteCustomer(
  userToken: string,
  partnerCustomerId: number,
  customerId: number,
  deleteDate: string
): Promise<any> {
  try {
    const response = await axios.delete(`${BASEURL}/partner/customers/${customerId}`, {
      headers: { Authorization: userToken },
      params: { partnerCustomerId, deleteDate }, // Include deleteDate as required
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}


// Function to fetch customers
export async function fetchCustomers(userToken: string, partnerCustomerId: number): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/partner/customers`, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: {
        partnerCustomerId,
      },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to fetch active users for a specific customer
export async function fetchActiveUsers(
  userToken: string,
  partnerCustomerId: number,
  customerId: number
): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/partner/customers/${customerId}/active-users`, {
      headers: { Authorization: userToken },
      params: { partnerCustomerId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to fetch customer statistics
export async function fetchCustomerStatistics(userToken: string, partnerCustomerId: number, customerId: number): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/partner/customers/${customerId}/statistics`, {
      headers: { Authorization: userToken },
      params: { partnerCustomerId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to reset a user's password
export async function resetPassword(userToken: string, partnerCustomerId: number, userId: number): Promise<any> {
  try {
    const response = await axios.post(`${BASEURL}/partner/reset-password`, {
      userId,
    }, {
      headers: { Authorization: userToken },
      params: { partnerCustomerId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to fetch collateral data
export async function fetchCollateral(userToken: string, partnerCustomerId: number): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/partner/collateral`, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: { partnerCustomerId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to fetch invoices
export async function fetchInvoices(userToken: string, partnerCustomerId: number): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/partner/invoices`, {
      headers: {
        Authorization: userToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      params: { partnerCustomerId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to fetch partner announcements
export async function fetchPartnerAnnouncements(userToken: string, partnerCustomerId: number): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/partner/announcements`, {
      headers: { Authorization: userToken },
      params: { partnerCustomerId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}

// Function to fetch available modules
export async function fetchModules(userToken: string, partnerCustomerId: number): Promise<any> {
  try {
    const response = await axios.get(`${BASEURL}/partner/modules`, {
      headers: { Authorization: userToken },
      params: { partnerCustomerId },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.message || error.message;
      return {
        success: false,
        message: errorMessage,
      };
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred',
      };
    }
  }
}