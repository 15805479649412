import React, { useState, useEffect } from 'react'
import { addCustomer, fetchModules } from '../../app.service'
import { useAppContext } from '../../AppContext'

interface AddCustomerProps {
  onClose: () => void
  onSuccess: () => void
}

interface Tier {
  unit?: string
  tierName: string
  description?: string
  featureList?: string[]
  licenseType?: string
  stripeTaxId?: string
  responseLimit?: number
  stripePriceId?: string
  stripeProductId?: string
}

interface Module {
  moduleId: number
  moduleName: string
  cardDescription?: string
  tiers: Tier[]
  environment: string
  fullDescription?: string
  fullDescriptionList?: string[]
  icon?: string
  created: string
  updated: string
}

const AddCustomer: React.FC<AddCustomerProps> = ({ onClose, onSuccess }) => {
  const [customerName, setCustomerName] = useState('')
  const [organisationalNumber, setOrganisationalNumber] = useState('')
  const [partnerOrganizationId, setPartnerOrganizationId] = useState('')
  const [startDate, setStartDate] = useState<string>('')
  const [minAmountOfUsers, setMinAmountOfUsers] = useState<number | string>('')
  const [clientId, setClientId] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  const [apiUrl, setApiUrl] = useState('')
  const [accessTokenUrl, setAccessTokenUrl] = useState('')
  const [administrators, setAdministrators] = useState<
    { email: string; userName: string; integrationUserId: string }[]
  >([])
  const [newAdmin, setNewAdmin] = useState<{
    email: string
    userName: string
    integrationUserId: string
  }>({ email: '', userName: '', integrationUserId: '' })
  const [showAdminInput, setShowAdminInput] = useState(false)
  const [modules, setModules] = useState<Module[]>([])
  const [selectedTiers, setSelectedTiers] = useState<{
    [moduleId: string]: string
  }>({})
  const [errorMessage, setErrorMessage] = useState('')
  const { currentToken, currentCustomer } = useAppContext()

  useEffect(() => {
    const loadModules = async () => {
      if (currentToken) {
        const result = await fetchModules(
          currentToken,
          currentCustomer.customerId,
        )
        if (result.success) {
          const filteredModules = result.data.filter(
            (module: Module) => module.moduleName !== 'SMS',
          )
          setModules(filteredModules)
        } else {
          console.error('Error fetching modules:', result.message)
        }
      }
    }
    loadModules()
  }, [currentToken, currentCustomer.customerId])

  const handleAddCustomer = async () => {
    // Convert minAmountOfUsers to a number if it's a string
    const parsedMinAmountOfUsers =
      typeof minAmountOfUsers === 'string'
        ? parseInt(minAmountOfUsers, 10)
        : minAmountOfUsers

    if (
      !customerName ||
      !organisationalNumber ||
      !partnerOrganizationId ||
      !startDate ||
      !parsedMinAmountOfUsers ||
      !clientId ||
      !clientSecret ||
      !apiUrl ||
      !accessTokenUrl
    ) {
      setErrorMessage('Please fill in all customer details.')
      return
    }

    if (!administrators.length) {
      setErrorMessage('Please add at least one administrator.')
      return
    }

    const customerData = {
      customerName,
      organisationalNumber,
      partnerOrganizationId,
      startDate,
      minAmountOfUsers: parsedMinAmountOfUsers,
      clientCredentials: {
        clientId,
        clientSecret,
        apiUrl,
        accessTokenUrl,
      },
      administrators,
      modules: selectedTiers,
    }

    if (currentToken && currentCustomer) {
      const result = await addCustomer(
        customerData,
        currentToken,
        currentCustomer.customerId,
      )
      if (result.success) {
        onSuccess()
      } else {
        console.error('Error adding customer:', result.message)
      }
    }
  }

  const handleAddAdministrator = () => {
    if (!newAdmin.email || !newAdmin.userName || !newAdmin.integrationUserId) {
      setErrorMessage('Please fill in all administrator details.')
      return
    }

    setAdministrators([...administrators, newAdmin])
    setNewAdmin({ email: '', userName: '', integrationUserId: '' })
    setShowAdminInput(false)
    setErrorMessage('')
  }

  const handleCancelAddAdministrator = () => {
    setNewAdmin({ email: '', userName: '', integrationUserId: '' })
    setShowAdminInput(false)
  }

  const removeAdministrator = (index: number) => {
    const newAdministrators = administrators.filter((_, i) => i !== index)
    setAdministrators(newAdministrators)
  }

  const handleTierSelection = (moduleId: string, tierName: string) => {
    setSelectedTiers((prev) => ({
      ...prev,
      [moduleId]: prev[moduleId] === tierName ? '' : tierName,
    }))
  }

  const handleMinUsersBlur = () => {
    if (typeof minAmountOfUsers === 'number' && minAmountOfUsers < 50) {
      setMinAmountOfUsers(50)
    }
  }

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white p-8 rounded-xl shadow-lg w-[800px]">
        <h2 className="text-xl font-bold mb-4">Add New Customer</h2>
        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

        {/* Two Column Layout */}
        <div className="flex space-x-2">
          {/* Customer Details Section */}
          <div className="flex-1  rounded py-4">
            <h3 className="text-lg font-bold mb-4">Customer Details</h3>
            <label className="block mt-4 text-sm">Customer Name</label>
            <input
              type="text"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              className="mt-1 p-1 border w-full rounded-lg"
              required
            />
            <label className="block mt-4 text-sm">Organisational Number</label>
            <input
              type="text"
              value={organisationalNumber}
              onChange={(e) => setOrganisationalNumber(e.target.value)}
              className="mt-1 p-1 border w-full rounded-lg"
              required
            />
            <label className="block mt-4 text-sm">
              Partner Organization ID
            </label>
            <input
              type="text"
              value={partnerOrganizationId}
              onChange={(e) => setPartnerOrganizationId(e.target.value)}
              className="mt-1 p-1 border w-full rounded-lg"
              required
            />
            <label className="block mt-4 text-sm">Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="mt-1 p-1 border w-full rounded-lg"
              required
            />
            <label className="block mt-4 text-sm">
              Minimum Amount of Users
            </label>
            <input
              type="number"
              min={50}
              value={minAmountOfUsers}
              onChange={(e) => setMinAmountOfUsers(e.target.value)}
              onBlur={handleMinUsersBlur}
              className="mt-1 p-1 border w-full rounded-lg"
              required
            />
          </div>

          {/* Client Credentials Section */}
          <div className="flex-1  rounded p-4">
            <h3 className="text-lg font-bold mb-4">Client Credentials</h3>
            <label className="block mt-4 text-sm">Client ID</label>
            <input
              type="text"
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
              className="mt-1 p-1 border w-full rounded-lg"
              required
            />
            <label className="block mt-4 text-sm">Client Secret</label>
            <input
              type="text"
              value={clientSecret}
              onChange={(e) => setClientSecret(e.target.value)}
              className="mt-1 p-1 border w-full rounded-lg"
              required
            />
            <label className="block mt-4 text-sm">API URL</label>
            <input
              type="text"
              value={apiUrl}
              onChange={(e) => setApiUrl(e.target.value)}
              className="mt-1 p-1 border w-full rounded-lg"
              required
            />
            <label className="block mt-4 text-sm">Access Token URL</label>
            <input
              type="text"
              value={accessTokenUrl}
              onChange={(e) => setAccessTokenUrl(e.target.value)}
              className="mt-1 p-1 border w-full rounded-lg"
              required
            />
          </div>
        </div>

        {/* Modules Section */}
        {modules.length > 0 && (
          <div className="border rounded-lg p-4 mt-4">
            <h3 className="text-lg font-bold mb-4">Modules</h3>
            {modules.map((module) => (
              <div key={module.moduleId} className="mb-4">
                {module.tiers.map((tier) => (
                  <div key={tier.tierName} className="flex items-center mt-2">
                    <input
                      type="checkbox"
                      id={`${module.moduleId}-${tier.tierName}`}
                      name={`module-${module.moduleId}`}
                      value={tier.tierName}
                      checked={selectedTiers[module.moduleId] === tier.tierName}
                      onChange={() =>
                        handleTierSelection(
                          module.moduleId.toString(),
                          tier.tierName,
                        )
                      }
                    />
                    <label
                      htmlFor={`${module.moduleId}-${tier.tierName}`}
                      className="ml-2"
                    >
                      {tier.tierName}
                    </label>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}

        {/* Administrators Section */}
        <h3 className="text-lg font-bold mt-4">Administrators</h3>
        {!showAdminInput && (
          <button
            className="mt-2 mb-4 bg-green-500 text-white px-4 py-2 rounded-lg text-sm"
            onClick={() => setShowAdminInput(true)}
          >
            Add Administrator
          </button>
        )}

        {/* Administrator Input Fields (Horizontal Layout) */}
        {showAdminInput && (
          <div className=" rounded mb-4 flex items-center space-x-2">
            <input
              type="email"
              placeholder="Email"
              value={newAdmin.email}
              onChange={(e) =>
                setNewAdmin({ ...newAdmin, email: e.target.value })
              }
              className="p-1 border w-full rounded-lg text-sm"
              required
            />
            <input
              type="text"
              placeholder="Name"
              value={newAdmin.userName}
              onChange={(e) =>
                setNewAdmin({ ...newAdmin, userName: e.target.value })
              }
              className="p-1 border w-full rounded-lg text-sm"
              required
            />
            <input
              type="text"
              placeholder="Integration User ID"
              value={newAdmin.integrationUserId}
              onChange={(e) =>
                setNewAdmin({ ...newAdmin, integrationUserId: e.target.value })
              }
              className="p-1 border w-full rounded-lg text-sm"
              required
            />
            <div className="flex space-x-2">
              <button
                className="bg-green-500 text-white px-6 py-1 text-sm rounded-lg"
                onClick={handleAddAdministrator}
              >
                Save
              </button>
              <button
                className="bg-gray-400 text-white px-4 py-1 text-sm rounded-lg"
                onClick={handleCancelAddAdministrator}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* List of Administrators with Fixed Height and Overflow Scroll */}
        {administrators.length > 0 && (
          <div
            className="border rounded p-4 mb-4"
            style={{ maxHeight: '150px', overflowY: 'auto' }}
          >
            {administrators.map((admin, index) => (
              <div
                key={index}
                className="flex justify-between items-center p-1 border-b last:border-0"
              >
                <div className="flex space-x-4">
                  <span>{admin.email}</span>
                  <span>{admin.userName}</span>
                  <span>{admin.integrationUserId}</span>
                </div>
                <button
                  className="bg-red-500 text-white px-2 py-1 rounded"
                  onClick={() => removeAdministrator(index)}
                >
                  Delete
                </button>
              </div>
            ))}
          </div>
        )}

        {/* Action Buttons */}
        <div className="mt-12 flex justify-start space-x-4">
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
            onClick={handleAddCustomer}
          >
            Add Customer
          </button>
          <button
            className="bg-gray-400 text-white px-4 py-2 rounded-lg"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddCustomer
