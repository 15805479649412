import React, { useEffect, useState } from 'react'
import { fetchPartnerAnnouncements } from '../../app.service'
import { useAppContext } from '../../AppContext'
import { Input } from '../ui/input'
import { DateTime } from 'luxon'

interface Announcement {
  id: number
  title: string
  content: string
  starred: boolean
  created: string
}

const Home: React.FC = () => {
  const { currentUser, currentToken, currentCustomer } = useAppContext()
  const [announcements, setAnnouncements] = useState<Announcement[]>([])
  const [starredAnnouncements, setStarredAnnouncements] = useState<
    Announcement[]
  >([])

  useEffect(() => {
    const fetchAnnouncements = async () => {
      if (currentToken && currentCustomer) {
        const partnerCustomerId = currentCustomer.customerId
        const result = await fetchPartnerAnnouncements(
          currentToken,
          partnerCustomerId,
        )
        if (result.success) {
          const allAnnouncements = result.data

          // Get the first 3 starred announcements
          const starred = allAnnouncements
            .filter((announcement: Announcement) => announcement.starred)
            .slice(0, 3)
          setStarredAnnouncements(starred)

          // Get the rest of the announcements including any starred announcements not in the top 3
          const nonStarred = allAnnouncements.filter(
            (announcement: Announcement) =>
              !announcement.starred || !starred.includes(announcement),
          )
          setAnnouncements(nonStarred)
        } else {
          console.error('Error fetching announcements:', result.message)
        }
      }
    }

    fetchAnnouncements()
  }, [currentToken, currentCustomer])

  const [inputValue, setInputValue] = React.useState('')
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  return (
    <div>
      <div className="bg-gradient-to-r from-indigo-400 to-cyan-400 h-72 flex flex-col">
        <div className="max-w-[1200px] m-auto">
          <div className="flex gap-10 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-32 stroke-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46"
              />
            </svg>

            <div className="flex flex-col items-start justify-center h-full gap-6">
              <span className="text-5xl  text-white font-bold">
                Announcements
              </span>
              <Input
                className="w-[30rem]"
                type="search"
                placeholder="Search"
                value={inputValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 max-w-[1200px] m-auto mt-10">
        <p className="text-lg">Here’s the latest news and updates:</p>

        <div className="mt-6 space-y-4">
          {starredAnnouncements.map((announcement) => {
            const date = DateTime.fromISO(announcement?.created)
            const monthDay = date.toFormat('LLL dd')
            const year = date.toFormat('yyyy')
            return (
              <div
                key={announcement.id}
                className="flex divide-x-2 border rounded-xl p-4"
              >
                <div className="flex flex-col pr-4 text-slate-400 w-20">
                  <div className="">{monthDay}</div>
                  <div className="">{year}</div>
                </div>
                <div className="flex flex-col pl-4 w-full">
                  <h2 className="text-lg font-semibold flex justify-between w-full text-blue-400">
                    {announcement.title}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6 stroke-amber-400"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.362 5.214A8.252 8.252 0 0 1 12 21 8.25 8.25 0 0 1 6.038 7.047 8.287 8.287 0 0 0 9 9.601a8.983 8.983 0 0 1 3.361-6.867 8.21 8.21 0 0 0 3 2.48Z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 18a3.75 3.75 0 0 0 .495-7.468 5.99 5.99 0 0 0-1.925 3.547 5.975 5.975 0 0 1-2.133-1.001A3.75 3.75 0 0 0 12 18Z"
                      />
                    </svg>
                  </h2>
                  <p className="mt-2 text-gray-600">{announcement.content}</p>
                </div>
              </div>
            )
          })}
        </div>

        {/* Regular Announcements */}
        <div className="mt-6 space-y-4">
          {announcements.map((announcement) => {
            const date = DateTime.fromISO(announcement?.created)
            const monthDay = date.toFormat('LLL dd')
            const year = date.toFormat('yyyy')
            return (
              <div
                key={announcement.id}
                className="flex divide-x-2  p-4 w-full"
              >
                <div className="flex flex-col pr-4 text-slate-400 w-20">
                  <div className="">{monthDay}</div>
                  <div className="">{year}</div>
                </div>
                <div className="flex flex-col pl-4">
                  <h2 className="text-lg font-semibold flex justify-between w-full">
                    {announcement.title}
                  </h2>
                  <p className="mt-2 text-gray-600">{announcement.content}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Home
