import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the shape of your context state
type AppContextType = {
  currentToken: string | null;
  setCurrentToken: (token: string | null) => void;
  currentUser: any;
  setCurrentUser: (user: any) => void;
  currentCustomer: any;
  setCurrentCustomer: (customer: any) => void;
  authorized: boolean;
  setAuthorized: (authorized: boolean) => void;
};

const AppContext = createContext<AppContextType | undefined>(undefined);

// Create a provider component
export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentToken, setCurrentToken] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [currentCustomer, setCurrentCustomer] = useState<any>(null);
  const [authorized, setAuthorized] = useState<boolean>(false); // Initialize authorization state

  return (
    <AppContext.Provider value={{ currentToken, setCurrentToken, currentUser, setCurrentUser, currentCustomer, setCurrentCustomer, authorized, setAuthorized }}>
      {children}
    </AppContext.Provider>
  );
};

// Create a custom hook to use the AppContext
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
