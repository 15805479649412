import React, { useState, useEffect } from 'react'
import { useAppContext } from '../../AppContext'
import { getAccounts, createOpportunity } from '../../app.service'

interface AddOpportunityProps {
  onClose: () => void
  onSuccess: () => void
}

const AddOpportunity: React.FC<AddOpportunityProps> = ({
  onClose,
  onSuccess,
}) => {
  const { currentToken, currentCustomer } = useAppContext()
  const [accounts, setAccounts] = useState<any[]>([])
  const [selectedAccountId, setSelectedAccountId] = useState<string>('')
  const [accountName, setAccountName] = useState<string>('')
  const [organizationalNumber, setOrganizationalNumber] = useState<string>('')
  const [closeDate, setCloseDate] = useState<string>('')
  const [amountOfUsers, setAmountOfUsers] = useState<number>(50) // Default minimum set to 50
  const [selectedModules, setSelectedModules] = useState<string[]>([])
  const [errors, setErrors] = useState<{ [key: string]: string }>({}) // To store error messages

  useEffect(() => {
    // Fetch existing accounts
    const fetchAccounts = async () => {
      if (currentToken) {
        try {
          const response = await getAccounts(
            currentToken,
            currentCustomer.customerId,
          )
          setAccounts(response.data || []) // Ensure accounts is set to an empty array if data is undefined or null
        } catch (error) {
          console.error('Error fetching accounts:', error)
          setAccounts([]) // Set accounts to an empty array if there's an error fetching data
        }
      }
    }

    fetchAccounts()
  }, [currentToken])

  const handleAccountChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAccountId(e.target.value)
  }

  const handleModuleChange = (module: string) => {
    setSelectedModules((prev) =>
      prev.includes(module)
        ? prev.filter((m) => m !== module)
        : [...prev, module],
    )
  }

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {}

    if (selectedAccountId === 'new') {
      if (!accountName) newErrors.accountName = 'Account name is required'
      if (!organizationalNumber)
        newErrors.organizationalNumber = 'Organizational number is required'
    } else {
      if (!selectedAccountId)
        newErrors.selectedAccountId = 'Account is required'
    }
    if (!closeDate) newErrors.closeDate = 'Close date is required'
    if (amountOfUsers < 50)
      newErrors.amountOfUsers = 'Amount of users must be at least 50'

    setErrors(newErrors)

    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!validateForm()) {
      return
    }

    // Ensure currentToken is not null
    if (!currentToken) {
      console.error('No token available')
      return // Exit the function if currentToken is null
    }

    try {
      const opportunityData = {
        AccountId: selectedAccountId !== 'new' ? selectedAccountId : null,
        AddNewAccount: selectedAccountId === 'new',
        Account:
          selectedAccountId === 'new'
            ? {
                Name: accountName,
                Organisational_Number__c: organizationalNumber,
              }
            : null,
        CloseDate: closeDate,
        Amount_of_Users__c: amountOfUsers,
        Modules__c: selectedModules.join(';'), // Salesforce typically uses semi-colons for multi-select fields
      }

      // Create the opportunity
      const opportunityResponse = await createOpportunity(
        opportunityData,
        currentToken,
        currentCustomer.customerId,
      )

      onSuccess() // Refresh the opportunities list or perform other success actions
      onClose() // Close the modal
    } catch (error) {
      console.error('Error creating opportunity:', error)
    }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
        <h2 className="text-xl font-bold mb-4">Add Opportunity</h2>
        <form onSubmit={handleSubmit}>
          {/* Account Selection */}
          <div className="mb-4">
            <label
              htmlFor="account"
              className="block text-sm font-medium text-gray-700"
            >
              Account
            </label>
            <select
              id="account"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              value={selectedAccountId}
              onChange={handleAccountChange}
            >
              <option disabled value="">
                Select a value..
              </option>
              <option value="new">Add new account</option>
              {accounts.length > 0 ? (
                accounts.map((account) => (
                  <option key={account.Id} value={account.Id}>
                    {account.Name}
                  </option>
                ))
              ) : (
                <option disabled>No accounts available</option>
              )}
            </select>
            {errors.selectedAccountId && (
              <span className="text-red-500 text-sm">
                {errors.selectedAccountId}
              </span>
            )}
          </div>

          {/* New Account Fields */}
          {selectedAccountId === 'new' && (
            <>
              <div className="mb-4">
                <label
                  htmlFor="accountName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Account Name
                </label>
                <input
                  type="text"
                  id="accountName"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  value={accountName}
                  onChange={(e) => setAccountName(e.target.value)}
                />
                {errors.accountName && (
                  <span className="text-red-500 text-sm">
                    {errors.accountName}
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="organizationalNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  Organizational Number
                </label>
                <input
                  type="text"
                  id="organizationalNumber"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  value={organizationalNumber}
                  onChange={(e) => setOrganizationalNumber(e.target.value)}
                />
                {errors.organizationalNumber && (
                  <span className="text-red-500 text-sm">
                    {errors.organizationalNumber}
                  </span>
                )}
              </div>
            </>
          )}

          {/* Opportunity Fields */}
          <div className="mb-4">
            <label
              htmlFor="closeDate"
              className="block text-sm font-medium text-gray-700"
            >
              Close Date
            </label>
            <input
              type="date"
              id="closeDate"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              value={closeDate}
              onChange={(e) => setCloseDate(e.target.value)}
            />
            {errors.closeDate && (
              <span className="text-red-500 text-sm">{errors.closeDate}</span>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="amountOfUsers"
              className="block text-sm font-medium text-gray-700"
            >
              Amount of Users
            </label>
            <input
              type="number"
              id="amountOfUsers"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              value={amountOfUsers}
              min={50} // Minimum set to 50
              onChange={(e) =>
                setAmountOfUsers(Math.max(50, Number(e.target.value)))
              }
            />
            {errors.amountOfUsers && (
              <span className="text-red-500 text-sm">
                {errors.amountOfUsers}
              </span>
            )}
          </div>

          {/* Modules Checkbox */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Modules
            </label>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="surveysModule"
                className="mr-2"
                checked={selectedModules.includes('Surveys Module')}
                onChange={() => handleModuleChange('Surveys Module')}
              />
              <label htmlFor="surveysModule">Surveys Module</label>
            </div>
          </div>

          {/* Buttons */}
          <div className="flex justify-end ">
            <button
              type="button"
              className="mr-4 bg-gray-300 text-gray-700 px-4 py-2 rounded"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Save Opportunity
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddOpportunity
