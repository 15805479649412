import React, { useEffect, useState } from 'react'
import SupportTicketForm from './SupportTicketForm'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '../ui/sheet'
import { Input } from '../ui/input'
import UserTickets from './UserTickets'
import { useAppContext } from '../../AppContext'

type Props = {}

function Support({}: Props) {
  interface Ticket {
    id: string
    name: string
    status: {
      status: string
      color: string
    }
    date_created: string
    date_updated: string
    priority: {
      color: string
      priority: string
    }
    custom_fields: Array<{
      id: string
      name: string
      type: string
      type_config?: {
        options?: Array<{
          id: string
          name: string
          color: string
          orderindex: number
        }>
      }
      value?: string | number | null
      date_created: string
    }>
  }

  const { currentUser } = useAppContext()
  const [ticketsArr, setTicketsArr] = useState<Ticket[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const API_TOKEN = process.env.REACT_APP_CLICKUP_API_KEY
  const LIST_ID = process.env.REACT_APP_CLICKUP_LIST_ID

  const CUSTOM_FIELD_ID = '291550f1-7cd9-4489-a857-cc9372d2d0d3'
  const CUSTOM_FIELD_VALUE = currentUser.customerId

  const handleDeleteTicket = (ticketId: string) => {
    setTicketsArr((prevTickets) =>
      prevTickets.filter((ticket) => ticket.id !== ticketId),
    )
  }

  const fetchTickets = async (ticketsData?: any) => {
    const url = `https://api.clickup.com/api/v2/list/${LIST_ID}/task?custom_fields=[{"field_id":"${CUSTOM_FIELD_ID}","operator":"=","value":"${CUSTOM_FIELD_VALUE}"}]`

    if (!API_TOKEN) {
      console.error('API token is missing')
      return
    }

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: API_TOKEN,
          'Content-Type': 'application/json',
        },
      })

      if (!response.ok) {
        throw new Error('Failed to fetch tickets')
      }

      const data = await response.json()

      setTicketsArr(data.tasks)
    } catch (err) {
      setError('Error fetching tickets. Please try again later.')
      console.error('Error fetching tickets:', err)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (currentUser) {
      fetchTickets()
    }
  }, [currentUser])

  return (
    <div className="flex flex-col h-screen">
      <Sheet>
        {/* Top Bar */}
        <div className="flex justify-between items-center p-4 h-20 bg-white border-b">
          <h1 className="text-2xl font-bold">Support</h1>
          <SheetTrigger className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center gap-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            Create ticket
          </SheetTrigger>
        </div>
        <div>
          {/* <UserTickets tickets={ticketsArr} isLoading={loading} error={error} /> */}
          <UserTickets
            tickets={ticketsArr}
            isLoading={loading}
            error={error}
            onDelete={handleDeleteTicket}
          />
        </div>
        <SheetContent side="right" className="w-[600px] !max-w-none">
          <SheetHeader className="h-full">
            <SheetTitle className="text-2xl">New ticket</SheetTitle>

            <SupportTicketForm onTicketSubmit={fetchTickets} />
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </div>
  )
}

export default Support
