import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table'
import Spinner from '../ui/spinner'
import { DateTime } from 'luxon'
import SupportTicketEdit from './SupportTicketEdit'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'

interface Ticket {
  id: string
  name: string
  status: {
    status: string
    color: string
  }
  date_created: string
  date_updated: string
  priority: {
    color: string
    priority: string
  }
  custom_fields: Array<{
    id: string
    name: string
    type: string
    type_config?: {
      options?: Array<{
        id: string
        name: string
        color: string
        orderindex: number
      }>
    }
    value?: string | number | null
    date_created: string
  }>
}

interface UserTicketsProps {
  tickets: Ticket[]
  isLoading: boolean
  error: string | null
  onDelete: (ticketId: string) => void
}

const UserTickets: React.FC<UserTicketsProps> = ({
  tickets,
  isLoading,
  error,
  onDelete,
}) => {
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null)
  const [loading, setLoading] = useState(false)

  const deleteTicket = async (ticketId: string) => {
    setLoading(true)
    const API_TOKEN = process.env.REACT_APP_CLICKUP_API_KEY
    const API_URL = `https://api.clickup.com/api/v2/task/${ticketId}`

    if (!API_TOKEN) {
      console.error('API token is missing')
      return
    }

    try {
      const response = await fetch(API_URL, {
        method: 'DELETE',
        headers: {
          Authorization: API_TOKEN,
        },
      })

      if (!response.ok) {
        throw new Error('Failed to delete the ticket.')
      }

      onDelete(ticketId)
    } catch (err) {
      console.error('Error deleting ticket:', err)
    } finally {
      setLoading(false)
    }
  }

  if (isLoading) {
    return (
      <div className="w-full h-96 flex justify-center items-center">
        <Spinner />
      </div>
    )
  }

  if (error) {
    return <p className="text-red-500">{error}</p>
  }

  if (tickets.length === 0) {
    return (
      <div className="w-full h-96 flex justify-center items-center">
        <p>You have no open tickets</p>
      </div>
    )
  }

  return (
    <div className="">
      <Dialog>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[200px]">Name</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Type</TableHead>
              <TableHead>Priority</TableHead>
              {/* <TableHead>Created</TableHead> */}
              <TableHead>Updated</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {tickets.map((ticket) => {
              const typeField = ticket.custom_fields.find(
                (field) => field.name === 'Type',
              )

              const typeValue =
                typeof typeField?.value === 'number' ? typeField?.value : null

              const typeOption =
                typeValue !== null
                  ? typeField?.type_config?.options?.[typeValue]
                  : null

              return (
                <TableRow
                  onClick={() => setSelectedTicket(ticket)}
                  key={ticket.id}
                  className="border p-4 rounded"
                >
                  {/* Ticket Name */}
                  <TableCell className="font-bold">{ticket.name}</TableCell>

                  {/* Ticket Status */}
                  <TableCell>
                    <span
                      className={`py-1 px-4 rounded-sm text-xs text-white uppercase`}
                      style={{ backgroundColor: ticket?.status?.color }}
                    >
                      {ticket.status.status}
                    </span>
                  </TableCell>

                  {/* Ticket Type */}
                  <TableCell>
                    <div
                      className={`py-1 px-4 rounded-sm text-xs text-white uppercase w-36 text-center`}
                      style={{ backgroundColor: typeOption?.color || '#999' }} // Default color if none found
                    >
                      {typeOption?.name || 'Unknown Type'}
                    </div>
                  </TableCell>

                  {/* Ticket Priority */}
                  <TableCell>
                    <span
                      className={`py-1 px-4 rounded-sm text-xs flex gap-2 items-center w-max capitalize`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                        style={{
                          stroke: ticket?.priority?.color || '#999',
                        }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5"
                        />
                      </svg>

                      {ticket?.priority?.priority || 'No Priority'}
                    </span>
                  </TableCell>

                  {/* Ticket Creation Date */}
                  {/* <TableCell>
                  {DateTime.fromMillis(parseInt(ticket.date_created)).toFormat(
                    'yyyy-MM-dd HH:mm:ss',
                  )}
                </TableCell> */}
                  {/* Ticket Update Date */}
                  <TableCell>
                    {DateTime.fromMillis(
                      parseInt(ticket.date_updated),
                    ).toFormat('yyyy-MM-dd HH:mm:ss')}
                  </TableCell>
                  <TableCell className="flex justify-end gap-2">
                    <DialogTrigger className="border border-primary rounded-md px-4 py-1 text-primary">
                      Edit
                    </DialogTrigger>

                    {/* Delete Button */}
                    <button
                      onClick={() => deleteTicket(ticket.id)}
                      className="border border-red-500 rounded-md px-4 py-1 text-red-500"
                    >
                      Delete
                    </button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>

        <DialogContent>
          <DialogHeader>
            <DialogDescription>
              {selectedTicket && (
                <SupportTicketEdit
                  ticketId={selectedTicket.id}
                  onTicketUpdated={() => setSelectedTicket(null)}
                />
              )}
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default UserTickets
