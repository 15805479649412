import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { fetchCustomers } from '../../app.service' // Correct import here
import AddCustomer from './AddCustomer'
import { useAppContext } from '../../AppContext'
import { DateTime } from 'luxon'

interface Customer {
  customerId: number
  customerName: string
  organisationalNumber: string
  partnerOrganizationId: string
  created: string
}

const Customers: React.FC = () => {
  const [customers, setCustomers] = useState<Customer[]>([])
  const [showAddCustomer, setShowAddCustomer] = useState<boolean>(false)
  const { currentToken, currentCustomer } = useAppContext()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      if (currentToken && currentCustomer) {
        const result = await fetchCustomers(
          currentToken,
          currentCustomer.customerId,
        )
        if (result.success && Array.isArray(result.data)) {
          setCustomers(result.data)
        } else {
          console.error('Error fetching customers:', result.message)
          setCustomers([])
        }
      }
    }

    fetchData()
  }, [currentToken, currentCustomer])

  const handleAddCustomerClick = () => {
    setShowAddCustomer(true)
  }

  const handleAddCustomerSuccess = () => {
    setShowAddCustomer(false)
    if (currentToken && currentCustomer) {
      fetchCustomers(currentToken, currentCustomer.customerId).then(
        (result) => {
          if (result.success && Array.isArray(result.data)) {
            setCustomers(result.data)
          } else {
            console.error('Error refetching customers:', result.message)
            setCustomers([])
          }
        },
      )
    }
  }

  const handleCustomerClick = (customerId: number) => {
    navigate(`/customers/${customerId}`)
  }

  return (
    <div className="flex flex-col h-screen">
      {/* Top Bar */}
      <div className="flex justify-between items-center p-4 h-20 bg-white border-b">
        <h1 className="text-2xl font-bold">Customers</h1>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center gap-3"
          onClick={handleAddCustomerClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          Add Customer
        </button>
      </div>

      {/* Customers List */}
      <div className="flex flex-col h-[90%] p-4 gap-2 overflow-auto bg-slate-50">
        {customers.length > 0 ? (
          customers.map((customer) => (
            <div
              key={customer.customerId}
              className="border rounded-lg p-4  cursor-pointer bg-white hover:bg-blue-50 flex items-center"
              onClick={() => handleCustomerClick(customer.customerId)}
            >
              <div className="mr-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-10 stroke-blue-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
                  />
                </svg>
              </div>
              <div className="flex flex-col">
                <h3 className="font-semibold">{customer.customerName}</h3>
                <span className="grid grid-flow-col gap-8 text-sm text-slate-500">
                  <p>Organisational Number: {customer.organisationalNumber}</p>
                  <p>
                    Partner Organization ID: {customer.partnerOrganizationId}
                  </p>
                  <p>
                    Created:{' '}
                    {DateTime.fromISO(customer.created).toFormat('yyyy-MM-dd')}
                  </p>
                </span>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No customers available</p>
        )}
      </div>

      {/* Add Customer Modal */}
      {showAddCustomer && (
        <AddCustomer
          onClose={() => setShowAddCustomer(false)}
          onSuccess={handleAddCustomerSuccess}
        />
      )}
    </div>
  )
}

export default Customers
