import { useAppContext } from '../../AppContext'
import React, { useState } from 'react'
import UserTickets from './UserTickets'
import { Input } from '../ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import Spinner from '../ui/spinner'
import { DialogClose } from '@radix-ui/react-dialog'

interface SupportTicketFormProps {
  onTicketSubmit: () => void
}

const SupportTicketForm: React.FC<SupportTicketFormProps> = ({
  onTicketSubmit,
}) => {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [priority, setPriority] = useState('Low')
  const [type, setType] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)
  const { currentUser } = useAppContext()
  const [loading, setLoading] = useState(false)

  const createClickUpTask = async (
    title: string,
    description: string,
    priority: string,
    type: string,
    file: File | null,
    userEmail: string | null,
    userName: string | null,
  ) => {
    const API_URL = 'https://api.clickup.com/api/v2/list/901203926487/task'
    const API_TOKEN = process.env.REACT_APP_CLICKUP_API_KEY

    const priorityMapping: { [key: string]: number } = {
      Urgent: 1,
      High: 2,
      Normal: 3,
      Low: 4,
    }
    if (!API_TOKEN) {
      console.error('API token is missing')
      return { success: false, error: 'API token is missing' }
    }

    setLoading(true)
    try {
      const taskResponse = await fetch(API_URL, {
        method: 'POST',
        headers: {
          Authorization: API_TOKEN,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: title,
          description: `Issue raised by ${currentUser.userName} (${currentUser.email}):\n\n${description}`,
          priority: priorityMapping[priority],
          custom_fields: [
            {
              id: '291550f1-7cd9-4489-a857-cc9372d2d0d3',
              value: currentUser.customerId,
            },
            {
              id: '3624e798-16dc-469d-93bf-410181c8b8aa',
              value: currentUser.email,
            },
            {
              id: '2003ffef-8fc9-4089-bfee-b8f96f73e000',
              value: type,
            },
          ],
        }),
      })

      const taskData = await taskResponse.json()

      if (taskResponse.ok && file) {
        const taskId = taskData.id

        const formData = new FormData()
        formData.append('attachment', file)
        formData.append('filename', file.name)

        const uploadResponse = await fetch(
          `https://api.clickup.com/api/v2/task/${taskId}/attachment`,
          {
            method: 'POST',
            headers: {
              Authorization: API_TOKEN,
            },
            body: formData,
          },
        )

        const uploadData = await uploadResponse.json()

        if (!uploadResponse.ok) {
          return { success: false, error: uploadData.err }
        }
      }

      if (taskResponse.ok) {
        return { success: true, data: taskData }
      } else {
        return { success: false, error: taskData.err }
      }
    } catch (error) {
      console.error('Error creating ClickUp task or uploading file:', error)
      return {
        success: false,
        error: (error as Error).message || 'Unknown error',
      }
    } finally {
      setLoading(false)
    }
  }
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!title || !description || !type) {
      setError('Please fill in all fields and select a valid type.')
      return
    }

    const result = await createClickUpTask(
      title,
      description,
      priority,
      type,
      file,
      currentUser.email,
      currentUser.userName,
    )

    if (result && result.success) {
      setSuccess('Support ticket raised successfully!')
      setError(null)
      setTitle('')
      setDescription('')
      setPriority('Low')
      setType('')
      setFile(null)

      onTicketSubmit()
    } else {
      setError(
        (result && result.error) ||
          'There was an error creating the ticket. Please try again.',
      )
      setSuccess(null)
    }
  }

  const handleTypeChange = (value: string) => {
    setType(value)
  }

  const PRIORITY_OPTIONS = ['Low', 'Normal', 'High', 'Urgent']

  if (loading) {
    return (
      <div className="h-full w-full flex flex-col gap-4 justify-center items-center">
        <Spinner />
        Loading...
      </div>
    )
  }

  if (success) {
    return (
      <div className="w-full h-full flex flex-col justify-center items-center gap-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-16 stroke-primary"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
          />
        </svg>
        <div className="flex flex-col justify-center items-center">
          <span className="font-bold">{success}</span>
          <span className="">
            Our team will review your request and get back to you shortly.
          </span>
          <DialogClose className="mt-8 border border-primary text-primary px-4 py-2 rounded-lg text-sm">
            Ok
          </DialogClose>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full h-full flex">
      <div className="flex flex-col w-full justify-between">
        <form
          id="support-ticket-form"
          className="w-full mt-8"
          onSubmit={handleSubmit}
        >
          <div className="mb-4">
            <label className="block text-gray-700 text-sm">Title</label>

            <Input
              id="ticket-name"
              className=""
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm">Priority</label>
            <div className="flex space-x-2 mt-2">
              {PRIORITY_OPTIONS.map((option) => (
                <button
                  key={option}
                  type="button"
                  onClick={() => setPriority(option)}
                  className={`px-4 py-2 border w-full rounded-md flex gap-2 items-center justify-center text-sm ${
                    priority === option
                      ? 'border-primary bg-primary/10 text-primary'
                      : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-100'
                  }`}
                  aria-pressed={priority === option}
                >
                  <span
                    className={`${option === 'Low' && 'bg-gray-400'} ${
                      option === 'Normal' && 'bg-violet-400'
                    } ${option === 'High' && 'bg-amber-400'} ${
                      option === 'Urgent' && 'bg-rose-400'
                    } size-2 rounded-full block`}
                  />
                  {option}
                </button>
              ))}
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="border p-2 w-full rounded-md"
              rows={5}
              required
            />
          </div>
          <div className="flex gap-4 w-full">
            <div className="mb-4 w-full">
              <label className="block text-gray-700 text-sm">Type</label>
              <Select
                value={type || undefined}
                onValueChange={handleTypeChange}
              >
                <SelectTrigger className="">
                  <SelectValue placeholder="Select Type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="2">
                    <div className="flex gap-2 items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z"
                        />
                      </svg>
                      Question
                    </div>
                  </SelectItem>
                  <SelectItem value="1">
                    <div className="flex gap-2 items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 0 1-.657.643 48.39 48.39 0 0 1-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 0 1-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 0 0-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 0 1-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 0 0 .657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 0 0 5.427-.63 48.05 48.05 0 0 0 .582-4.717.532.532 0 0 0-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 0 0 .658-.663 48.422 48.422 0 0 0-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 0 1-.61-.58v0Z"
                        />
                      </svg>
                      Issue
                    </div>
                  </SelectItem>
                  <SelectItem value="3">
                    <div className="flex gap-2 items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
                        />
                      </svg>
                      Suggestion
                    </div>
                  </SelectItem>
                  <SelectItem value="0">
                    <div className="flex gap-2 items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.362 5.214A8.252 8.252 0 0 1 12 21 8.25 8.25 0 0 1 6.038 7.047 8.287 8.287 0 0 0 9 9.601a8.983 8.983 0 0 1 3.361-6.867 8.21 8.21 0 0 0 3 2.48Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 18a3.75 3.75 0 0 0 .495-7.468 5.99 5.99 0 0 0-1.925 3.547 5.975 5.975 0 0 1-2.133-1.001A3.75 3.75 0 0 0 12 18Z"
                        />
                      </svg>
                      Incident
                    </div>
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="mb-4 w-full">
              <label className="block text-gray-700 text-sm">Attach File</label>

              <Input
                id="ticket-attachment"
                className=""
                type="file"
                onChange={(e) => setFile(e.target.files?.[0] || null)}
              />
            </div>
          </div>
          <div className="text-sm">
            {error && <p className="text-rose-500">{error}</p>}
            {success && <p className="text-green-500">{success}</p>}
          </div>
        </form>
        <div className="flex gap-2 w-full">
          <button
            form="support-ticket-form"
            type="submit"
            className="bg-primary text-white px-4 py-2 rounded-lg text-sm flex gap-4 items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
              />
            </svg>
            Submit Ticket
          </button>
          <DialogClose className="border border-primary text-primary px-4 py-2 rounded-lg text-sm">
            Cancel
          </DialogClose>
        </div>
      </div>
    </div>
  )
}

export default SupportTicketForm
