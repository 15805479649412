import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  auth,
  logInWithEmailAndPassword,
  sendPasswordReset,
} from '../../firebase'; // Ensure this path matches your project structure
import { useAuthState } from 'react-firebase-hooks/auth';
import logo from '../../assets/img/logo.png';
import solutionsLogo from '../../assets/img/poweredby.png';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import successAnimation from '../../assets/lottie/success.json';
import './Login.css';
import { getPartnerCustomer, getUserByEmail } from '../../app.service';
import { useAppContext } from '../../AppContext';

const Login: React.FC = () => {
  const {
    setCurrentToken,
    setCurrentUser,
    setCurrentCustomer,
    authorized,
    setAuthorized,
  } = useAppContext();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [btnFinalized, setBtnFinalized] = useState<boolean>(false);
  const [result, setResult] = useState<{
    success: boolean;
    message?: string;
  } | null>(null);
  const [emailActive, setEmailActive] = useState<boolean>(false);
  const [passwordActive, setPasswordActive] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  useEffect(() => {
    const checkUserAccess = async () => {
      if (loading || !user) return;

      try {
        const idTokenResult = await user.getIdTokenResult();
        const customClaims = idTokenResult.claims;

        if (!customClaims.userToken) {
          throw new Error('Permission denied!');
        }

        setCurrentToken(customClaims.userToken as string);

        const response = await getUserByEmail(
          user.email as string,
          customClaims.userToken as string,
        );

        if (!response.success) {
          throw new Error('Permission denied!');
        }

        setCurrentUser(response.data);

        const customer = await getPartnerCustomer(
          response.data.customerId,
          customClaims.userToken as string,
        );

        if (
          !customer.success ||
          (customer.data.partner === 0 && customer.data.customerId !== 56)
        ) {
          throw new Error('Permission denied!');
        }

        setCurrentCustomer(customer.data);

        setAuthorized(true);
        navigate('/');
      } catch (error) {
        // Update the state for error display
        setResult({
          success: false,
          message: (error as Error).message || 'Something went wrong. Please try again!',
        });
        setAuthorized(false); // Explicitly set authorized to false when denied
        setBtnLoading(false); // Ensure loading state is reset
        setBtnFinalized(true); // Mark button action as finalized
        await auth.signOut();
      }
    };

    checkUserAccess();
  }, [user, loading]);

  const handleLogin = async (email: string, password: string) => {
    setBtnLoading(true);
    setBtnFinalized(false);
    setResult(null); // Reset result state at the beginning of the login attempt

    const login = await logInWithEmailAndPassword(email, password);

    if (login.success) {
      // Successful login; navigation and state updates happen in the useEffect
    } else {
      // Handle login errors
      let message = '';
      if (login.message.includes('auth/invalid-email')) {
        message = 'The email you provided is not a valid email address.';
      } else if (login.message.includes('auth/user-not-found')) {
        message = 'The email you provided could not be found.';
      } else if (login.message.includes('auth/wrong-password')) {
        message = 'Wrong password provided!';
      } else {
        message = 'Something went wrong. Please try again!';
      }
      setResult({ success: false, message });
      setBtnLoading(false); // Stop loading indicator
      setBtnFinalized(true); // Mark button action as finalized
    }
  };

  const sendPasswordResetLink = async (email: string) => {
    setBtnLoading(true);
    const reset = await sendPasswordReset(email);
    if (reset.success) {
      setResult({ success: true, message: 'Password reset link sent!' });
    } else {
      let message = '';
      if (reset.message.includes('auth/invalid-email')) {
        message = 'The email you provided is not a valid email address.';
      } else if (reset.message.includes('auth/user-not-found')) {
        message = 'The email you provided could not be found.';
      } else {
        message = 'Something went wrong. Please try again!';
      }
      setResult({ success: false, message });
    }
    setBtnLoading(false);
    setBtnFinalized(true);
  };

  const handleForgotPasswordBtnClick = () => {
    setForgotPassword(!forgotPassword);
    setBtnLoading(false);
    setBtnFinalized(false);
    setResult(null);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleEmailFocus = () => setEmailActive(true);
  const handlePasswordFocus = () => setPasswordActive(true);
  const handleEmailBlur = () => {
    if (!email) setEmailActive(false);
  };
  const handlePasswordBlur = () => {
    if (!password) setPasswordActive(false);
  };
  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);

  return (
    <div className="flex flex-col h-screen justify-start items-start lg:p-4 bg-[url('../assets/backgrounds/valley_r.svg')] bg-cover">
      <span className="flex justify-end items-center w-full h-full">
        <div className="w-full h-full lg:h-2/3 lg:w-[25em] lg:mr-44 flex flex-col items-center justify-center p-8 bg-white text-gray-700 lg:rounded-xl shadow-2xl">
          <div className="flex flex-col items-center justify-center w-full mb-8 lg:mb-12">
            <div className="mb-2 w-24">
              {btnFinalized && authorized ? (
                <Player
                  speed={0}
                  autoplay
                  keepLastFrame
                  renderer={'svg'}
                  src={successAnimation}
                  style={{ width: '80px' }}
                >
                  <Controls visible={false} />
                </Player>
              ) : (
                <img src={logo} alt="Intelly" className="p-4" />
              )}
            </div>
            <span className="flex flex-col items-center justify-center mb-4">
              {!forgotPassword ? (
                <>
                  <h1 className="text-xl font-bold">
                    Login to Intelly Partner
                  </h1>
                  <p className="text-md font-normal mt-2">
                    We're happy to see you back again!
                  </p>
                </>
              ) : (
                <>
                  {btnFinalized && result?.success ? (
                    <>
                      <h1 className="font-bold text-xl">Check your email</h1>
                      <p className="text-center mt-2">
                        We have sent a password reset link to your registered
                        email address
                      </p>
                    </>
                  ) : (
                    <>
                      <h1 className="text-xl font-bold">Trouble signing in?</h1>
                      <p className="text-md font-normal mt-2 text-center text-sm text-slate-500">
                        Enter your email address, and we'll send you a secure
                        link to reset your password.
                      </p>
                    </>
                  )}
                </>
              )}
            </span>
          </div>

          <form
            className="w-full"
            id="login-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin(email, password);
            }}
          >
            {!(btnFinalized && result?.success) && (
              <div className="relative mt-4">
                <label
                  htmlFor="email"
                  className={`absolute left-2 top-[.6rem] transition-all duration-200 ${
                    emailActive
                      ? 'text-xs -translate-y-5 translate-x-[-.17rem] bg-white text-sky-400 pl-1 pr-2'
                      : 'text-sm text-slate-500'
                  }`}
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-sky-400"
                  value={email}
                  onChange={handleEmailChange}
                  onFocus={handleEmailFocus}
                  onBlur={handleEmailBlur}
                />
              </div>
            )}

            {!forgotPassword && (
              <div className="relative mt-4">
                <label
                  htmlFor="password"
                  className={`absolute left-2 top-[.6rem] transition-all duration-200 ${
                    passwordActive
                      ? 'text-xs -translate-y-5 translate-x-[-.17rem] bg-white text-sky-400 pl-1 pr-2'
                      : 'text-sm text-slate-500'
                  }`}
                >
                  Password
                </label>
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  id="password"
                  className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-sky-400"
                  value={password}
                  onChange={handlePasswordChange}
                  onFocus={handlePasswordFocus}
                  onBlur={handlePasswordBlur}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute right-2 top-2"
                >
                  {passwordVisible ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mt-1 mr-1 stroke-slate-400"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45  0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mt-1 mr-1 stroke-slate-400"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  )}
                </button>
              </div>
            )}
          </form>

          {/* Display Error Messages */}
          <div className="flex w-full py-2">
            {result && !result.success && btnFinalized && (
              <div className="flex w-full text-xs text-red-400">
                {result.message}
              </div>
            )}
            <div className="flex w-full justify-end text-xs text-[#38bdf8] hover:text-[#a3d6ec]">
              {!forgotPassword && (
                <button onClick={handleForgotPasswordBtnClick}>
                  Forgot password
                </button>
              )}
            </div>
          </div>

          {!forgotPassword ? (
            <button
              form="login-form"
              id="submit"
              type="submit"
              className="bg-sky-400 rounded-lg text-white w-full h-12 mt-4"
              onClick={() => handleLogin(email, password)}
            >
              {btnLoading ? (
                <span className="loading loading-spinner no-animation"></span>
              ) : (
                <span>Login</span>
              )}
            </button>
          ) : (
            <div className="w-full">
              {btnFinalized && result?.success ? (
                <div className="flex flex-col gap-4 items-center">
                  <button
                    className="btn w-full no-animation font-semibold bg-sky-400 text-white hover:text-slate-600"
                    onClick={() => handleForgotPasswordBtnClick()}
                  >
                    Back to login
                  </button>
                  <span className="text-sm text-center text-slate-500 w-1/2">
                    Did not receive the email? Check your spam filter or try
                    again.
                  </span>
                </div>
              ) : (
                <div className="flex flex-col items-center gap-6 mt-6 w-full">
                  <button
                    className="btn w-full no-animation font-semibold bg-sky-400 text-white hover:text-slate-600"
                    onClick={() => sendPasswordResetLink(email)}
                  >
                    {btnLoading ? (
                      <span className="loading loading-spinner pr-2"></span>
                    ) : (
                      <span>Send password reset link</span>
                    )}
                  </button>
                  <div
                    className="text-sky-400 text-center flex items-center gap-2 cursor-pointer"
                    onClick={() => handleForgotPasswordBtnClick()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M17 10a.75.75 0 01-.75.75H5.612l4.158 3.96a.75.75 0 11-1.04 1.08l-5.5-5.25a.75.75 0 010-1.08l-5.5-5.25a.75.75 0 111.04 1.08L5.612 9.25H16.25A.75.75 0 0117 10z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Back to login
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="flex lg:hidden flex-col mt-12 ml-4 text-xs">
            Powered by
            <img
              className="h-6"
              src={solutionsLogo}
              alt="Powered by Intelly solution"
            />
          </div>
        </div>
      </span>
      <div className="hidden lg:flex flex-col ml-4 text-xs">
        Powered by
        <img
          className="h-8"
          src={solutionsLogo}
          alt="Powered by Intelly solution"
        />
      </div>
    </div>
  );
};

export default Login;
