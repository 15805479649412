import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { getAuth, signOut } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useAppContext } from '../../AppContext'
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar'
import { Button } from '../ui/button'
import partnerLogo from '../../assets/img/partner_logo.png'

const Sidebar: React.FC = () => {
  const auth = getAuth()
  const navigate = useNavigate()
  const {
    currentUser,
    currentCustomer,
    setCurrentToken,
    setCurrentUser,
    setAuthorized,
  } = useAppContext()
  const [user] = useAuthState(auth)

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Reset context state on successful sign-out
        setCurrentToken(null)
        setCurrentUser(null)
        setAuthorized(false)
        navigate('/login')
      })
      .catch((error) => {
        console.error('Error signing out: ', error)
      })
  }

  return (
    <section className="relative">
      <div className="absolute top-0 h-screen w-full bg-gradient-to-t from-pink-200 via-blue-100 to-sky-100 "></div>
      <div className="h-screen backdrop-blur-2xl bg-white/50 text-black w-64 p-4 flex flex-col justify-between z-50 border-r">
        {/* Top section */}
        <div>
          <div className="mb-10 h-18 flex gap-4 items-center px-2">
            <img alt="logo" src={partnerLogo} className="size-10" />

            <div className="font-light text-md uppercase">Partner portal</div>
          </div>

          {/* Navigation Links */}
          <nav className="flex flex-col space-y-4 ">
            <NavLink
              to="/"
              className={({ isActive }) =>
                `p-2 rounded-md ${
                  isActive ? 'bg-white shadow-sm ' : ''
                } flex justify-start text-black/70 font-semibold text-sm gap-3 items-center`
              }
            >
              {({ isActive }) => (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`size-6 ${
                      isActive ? 'text-blue-500' : 'text-black/70'
                    }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                    />
                  </svg>
                  Home
                </>
              )}
            </NavLink>
            <NavLink
              to="/opportunities"
              className={({ isActive }) =>
                `p-2 rounded-md ${
                  isActive ? 'bg-white shadow ' : ''
                } flex justify-start text-black/70 font-semibold text-sm gap-3 items-center`
              }
            >
              {({ isActive }) => (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`size-6 ${
                      isActive ? 'text-blue-500' : 'text-black/70'
                    }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0 1 20.25 6v12A2.25 2.25 0 0 1 18 20.25H6A2.25 2.25 0 0 1 3.75 18V6A2.25 2.25 0 0 1 6 3.75h1.5m9 0h-9"
                    />
                  </svg>
                  Opportunities
                </>
              )}
            </NavLink>
            <NavLink
              to="/customers" // Add the Customers route
              className={({ isActive }) =>
                `p-2 rounded-md ${
                  isActive ? 'bg-white shadow ' : ''
                } flex justify-start text-black/70 font-semibold text-sm gap-3 items-center`
              }
            >
              {({ isActive }) => (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`size-6 ${
                      isActive ? 'text-blue-500' : 'text-black/70'
                    }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                    />
                  </svg>
                  Customers
                </>
              )}
            </NavLink>
            <NavLink
              to="/invoices"
              className={({ isActive }) =>
                `p-2 rounded-md ${
                  isActive ? 'bg-white shadow ' : ''
                } flex justify-start text-black/70 font-semibold text-sm gap-3 items-center`
              }
            >
              {({ isActive }) => (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`size-6 ${
                      isActive ? 'text-blue-500' : 'text-black/70'
                    }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 11.625h4.5m-4.5 2.25h4.5m2.121 1.527c-1.171 1.464-3.07 1.464-4.242 0-1.172-1.465-1.172-3.84 0-5.304 1.171-1.464 3.07-1.464 4.242 0M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                    />
                  </svg>
                  Invoices
                </>
              )}
            </NavLink>
            <NavLink
              to="/collateral"
              className={({ isActive }) =>
                `p-2 rounded-md ${
                  isActive ? 'bg-white/90 shadow-sm' : ''
                } flex justify-start text-black/70 font-semibold text-sm gap-3 items-center`
              }
            >
              {({ isActive }) => (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`size-6 ${
                      isActive ? 'text-blue-500' : 'text-black/70'
                    }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
                    />
                  </svg>
                  Collateral
                </>
              )}
            </NavLink>
            <NavLink
              to="/support"
              className={({ isActive }) =>
                `p-2 rounded-md ${
                  isActive ? 'bg-white/90 shadow-sm' : ''
                } flex justify-start text-black/70 font-semibold text-sm gap-3 items-center`
              }
            >
              {({ isActive }) => (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`size-6 ${
                      isActive ? 'text-blue-500' : 'text-black/70'
                    }`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.712 4.33a9.027 9.027 0 0 1 1.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 0 0-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 0 1 0 9.424m-4.138-5.976a3.736 3.736 0 0 0-.88-1.388 3.737 3.737 0 0 0-1.388-.88m2.268 2.268a3.765 3.765 0 0 1 0 2.528m-2.268-4.796a3.765 3.765 0 0 0-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 0 1-1.388.88m2.268-2.268 4.138 3.448m0 0a9.027 9.027 0 0 1-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0-3.448-4.138m3.448 4.138a9.014 9.014 0 0 1-9.424 0m5.976-4.138a3.765 3.765 0 0 1-2.528 0m0 0a3.736 3.736 0 0 1-1.388-.88 3.737 3.737 0 0 1-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 0 1-1.652-1.306 9.027 9.027 0 0 1-1.306-1.652m0 0 4.138-3.448M4.33 16.712a9.014 9.014 0 0 1 0-9.424m4.138 5.976a3.765 3.765 0 0 1 0-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 0 1 1.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 0 0-1.652 1.306A9.025 9.025 0 0 0 4.33 7.288"
                    />
                  </svg>
                  Support
                </>
              )}
            </NavLink>
          </nav>
        </div>

        {/* Bottom section */}
        <span>
          <div className="my-4 border-t border-white py-4">
            <button
              onClick={handleLogout}
              className="p-2 rounded-md text-center mt-4 flex gap-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
                />
              </svg>
              Logout
            </button>
          </div>
          {/* User Profile */}

          <div className="flex items-center gap-4 bg-white rounded-lg p-2">
            {currentUser?.photoUrl ? (
              <img
                src={currentUser.photoUrl}
                alt="User Avatar"
                className="w-10 h-10 rounded-full"
              />
            ) : (
              <div className="w-10 h-10 rounded-full bg-slate-300"></div>
            )}

            <div className="flex flex-col">
              <span className="text-md font-bold">{currentUser?.userName}</span>
              <span className="text-sm">{currentCustomer?.customerName}</span>
            </div>
          </div>
        </span>
      </div>
    </section>
  )
}

export default Sidebar
