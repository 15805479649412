import React, { useState, useEffect } from 'react'
import { Input } from '../ui/input'
import { useAppContext } from '../../AppContext'
import Spinner from '../ui/spinner'
import { DialogClose } from '@radix-ui/react-dialog'

interface SupportTicketEditProps {
  ticketId: string
  onTicketUpdated: () => void
}

const SupportTicketEdit: React.FC<SupportTicketEditProps> = ({
  ticketId,
  onTicketUpdated,
}) => {
  const { currentUser } = useAppContext()
  const [ticket, setTicket] = useState<any | null>(null)
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState(false)

  const API_TOKEN = process.env.REACT_APP_CLICKUP_API_KEY
  const API_URL = `https://api.clickup.com/api/v2/task/${ticketId}`

  useEffect(() => {
    const fetchTicket = async () => {
      if (!API_TOKEN) {
        console.error('API token is missing')
        return
      }

      setLoading(true)
      try {
        const response = await fetch(API_URL, {
          headers: {
            Authorization: API_TOKEN,
          },
        })
        const data = await response.json()
        if (response.ok) {
          setTicket(data)
          setDescription(data.description)
        } else {
          setError('Failed to load ticket details.')
        }
      } catch (err) {
        setError('Error fetching ticket details.')
      } finally {
        setLoading(false)
      }
    }

    fetchTicket()
  }, [ticketId])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!API_TOKEN) {
      console.error('API token is missing')
      return
    }

    setLoading(true)
    try {
      const updateResponse = await fetch(API_URL, {
        method: 'PUT',
        headers: {
          Authorization: API_TOKEN,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          description: description,
        }),
      })

      const updateData = await updateResponse.json()

      if (updateResponse.ok) {
        setSuccess(true)
        setError(null)
      } else {
        setError(updateData.err || 'Failed to update ticket.')
      }
    } catch (error) {
      setError('Error updating ticket.')
      console.error('Error:', error)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return (
      <div className="h-96 w-full flex flex-col gap-4 justify-center items-center">
        <Spinner />
        Loading ticket details...
      </div>
    )
  }

  if (error) {
    return <p className="text-red-500">{error}</p>
  }

  if (success) {
    return (
      <div className="h-96 flex flex-col gap-8 justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-24 stroke-primary"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>

        <div className="">Ticket updated successfully</div>
        <DialogClose
          className="border border-primary text-primary px-4 py-2 w-24 rounded-lg text-sm"
          onClick={() => {
            onTicketUpdated()
          }}
        >
          Ok
        </DialogClose>
      </div>
    )
  }

  return (
    <div className="w-full h-full flex flex-col justify-between">
      <form onSubmit={handleSubmit}>
        {/* Ticket Details */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm">Title</label>
          <Input value={ticket?.name || ''} disabled />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm">Priority</label>
          <Input value={ticket?.priority?.priority || 'No Priority'} disabled />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm">Status</label>
          <Input value={ticket?.status?.status || 'No Status'} disabled />
        </div>
        {/* Editable Description */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="border p-2 w-full rounded-md"
            rows={5}
            required
          />
        </div>
        <div className="text-sm">
          {error && <p className="text-red-500">{error}</p>}
        </div>
        <div className="flex gap-2">
          <button
            type="submit"
            className="bg-primary text-white px-4 py-2 rounded-lg text-sm flex gap-4 items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
              />
            </svg>
            Update Description
          </button>
          <DialogClose className="border border-primary text-primary px-4 py-2 rounded-lg text-sm">
            Cancel
          </DialogClose>
        </div>
      </form>
    </div>
  )
}

export default SupportTicketEdit
