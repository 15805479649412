import React, { useState, useEffect } from 'react'
import { fetchInvoices } from '../../app.service'
import { useAppContext } from '../../AppContext'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table'
import { DateTime } from 'luxon'

interface Invoice {
  invoiceId: number
  customerId: number
  reference: string
  invoiceType: string
  startDate: string
  endDate: string
  invoicingEmail: string
  dueDate: string
  status: string
  invoiceSent: string
  subTotal: number
  vat: number
  amountVat: number
  totalAmount: number
  currency: string
  pdfUrl: string
  created: string
  updated: string
}

const Invoices: React.FC = () => {
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const { currentToken, currentCustomer } = useAppContext()

  useEffect(() => {
    const fetchInvoiceData = async () => {
      if (currentToken && currentCustomer) {
        const partnerCustomerId = currentCustomer.customerId
        const result = await fetchInvoices(currentToken, partnerCustomerId)
        if (result.success) {
          setInvoices(result.data)
        } else {
          console.error('Error fetching invoices:', result.message)
        }
      }
    }

    fetchInvoiceData()
  }, [currentToken, currentCustomer])

  function getStatusBgColor(status: string): string {
    switch (status.toLowerCase()) {
      case 'paid':
        return 'bg-green-400 text-green-800' // Green for paid invoices
      case 'pending':
        return 'bg-amber-400 text-amber-800' // Yellow for pending invoices
      case 'overdue':
        return 'bg-rose-400 text-rose-900' // Red for overdue invoices
      case 'draft':
        return 'bg-gray-400 text-gray-900' // Gray for draft invoices
      default:
        return 'bg-blue-400 text-white' // Default color for unknown statuses
    }
  }

  return (
    <div className="flex flex-col h-screen">
      {/* Top Bar */}
      <div className="flex justify-between items-center p-4 h-20 bg-white border-b">
        <h1 className="text-2xl font-bold">Invoices</h1>
      </div>

      {/* Invoices List */}
      <div className="bg-slate-50 h-full">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">Invoice ID</TableHead>
              <TableHead>Reference</TableHead>
              <TableHead>Type</TableHead>
              <TableHead>Start Date</TableHead>
              <TableHead>End Date</TableHead>
              <TableHead>Due Date</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Amount</TableHead>
              <TableHead>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                  />
                </svg>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {invoices.map((invoice) => {
              const bgColorClass = getStatusBgColor(invoice.status)
              return (
                <TableRow key={invoice.invoiceId} className="text-xs">
                  <TableCell className="font-semibold">
                    {invoice.invoiceId}
                  </TableCell>
                  <TableCell className="">{invoice.reference}</TableCell>
                  <TableCell className="">{invoice.invoiceType}</TableCell>
                  <TableCell className="">
                    {DateTime.fromISO(invoice.startDate).toFormat('yyyy-MM-dd')}
                  </TableCell>
                  <TableCell className="">
                    {DateTime.fromISO(invoice.endDate).toFormat('yyyy-MM-dd')}
                  </TableCell>
                  <TableCell className="">
                    {DateTime.fromISO(invoice.dueDate).toFormat('yyyy-MM-dd')}
                  </TableCell>
                  <TableCell>
                    <span
                      className={`${bgColorClass} rounded-full px-3  max-w-24 justify-center flex`}
                    >
                      {invoice.status}
                    </span>
                  </TableCell>
                  <TableCell className="">
                    {invoice.totalAmount} {invoice.currency}
                  </TableCell>
                  <TableCell className="cursor-pointer">
                    <a
                      className="flex justify-start w-max"
                      href={invoice.pdfUrl}
                      download
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-5 stroke-primary"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                        />
                      </svg>
                    </a>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default Invoices
