import React, { useState, useEffect } from 'react'
import { useAppContext } from '../../AppContext'
import { getOpportunities, updateOpportunity } from '../../app.service'
import OpportunityDetail from './OpportunityDetail'
import AddOpportunity from './AddOpportunity'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '../ui/sheet'
import { Input } from '../ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { format, parseISO } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'

import { cn } from '../../lib/utils'
import { Button } from '../ui/button'
import { Calendar } from '../ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'
import { Checkbox } from '../ui/checkbox'
import { Label } from '../ui/label'
import { DialogClose } from '@radix-ui/react-dialog'

const Opportunities: React.FC = () => {
  const { currentToken, currentCustomer } = useAppContext()
  const [openOpportunities, setOpenOpportunities] = useState<any[]>([])
  const [closedOpportunities, setClosedOpportunities] = useState<any[]>([])
  const [selectedOpportunity, setSelectedOpportunity] = useState<any | null>(
    null,
  )
  const [showAddOpportunity, setShowAddOpportunity] = useState<boolean>(false)

  // Function to fetch opportunities
  const fetchOpportunities = async () => {
    if (currentToken && currentCustomer) {
      try {
        const response = await getOpportunities(
          currentToken,
          currentCustomer.customerId,
        )

        if (response && response.data) {
          const { open = [], closed = [] } = response.data // Ensure defaults if data is undefined
          setOpenOpportunities(open)
          setClosedOpportunities(closed)
        } else {
          setOpenOpportunities([])
          setClosedOpportunities([])
        }
      } catch (error) {
        console.error('Error fetching opportunities:', error)
        setOpenOpportunities([]) // Fallback to empty array on error
        setClosedOpportunities([])
      }
    }
  }

  useEffect(() => {
    fetchOpportunities()
  }, [currentToken, currentCustomer])

  const handleOpportunityClick = (opportunity: any) => {
    setSelectedOpportunity(opportunity)
  }

  const handleAddOpportunityClick = () => {
    setShowAddOpportunity(true)
  }

  const stages = [
    'Prospecting',
    'Qualification',
    'Value Proposition',
    'Proposal/Price Quote',
    'Negotiation/Review',
    'Closed Won',
  ]

  /// UPDATE OPPORTUNITY START///
  const [amountOfUsers, setAmountOfUsers] = useState<number | undefined>(
    selectedOpportunity?.Amount_of_Users__c || undefined,
  )

  useEffect(() => {
    if (
      selectedOpportunity?.Amount_of_Users__c !== undefined &&
      selectedOpportunity?.Amount_of_Users__c !== null
    ) {
      setAmountOfUsers(selectedOpportunity.Amount_of_Users__c)
    } else {
      setAmountOfUsers(undefined)
    }
  }, [selectedOpportunity])
  const [stage, setStage] = useState<string | null>(null)

  useEffect(() => {
    if (selectedOpportunity?.StageName) {
      setStage(selectedOpportunity.StageName)
    } else {
      setStage(null)
    }
  }, [selectedOpportunity])

  const [modules, setModules] = useState<string[]>([])

  useEffect(() => {
    if (selectedOpportunity?.Modules__c) {
      setModules(
        selectedOpportunity.Modules__c.split(',').map((module: string) =>
          module.trim(),
        ),
      )
    } else {
      setModules([])
    }
  }, [selectedOpportunity])

  const handleModuleChange = (moduleName: string) => {
    setModules((prevModules) =>
      prevModules.includes(moduleName)
        ? prevModules.filter((m) => m !== moduleName)
        : [...prevModules, moduleName],
    )
  }

  const [closeDate, setCloseDate] = useState<string | null>(null)

  useEffect(() => {
    if (selectedOpportunity?.CloseDate) {
      setCloseDate(selectedOpportunity.CloseDate)
    } else {
      setCloseDate(null)
    }
  }, [selectedOpportunity])

  useEffect(() => {
    if (selectedOpportunity?.Modules__c) {
      setModules(selectedOpportunity?.Modules__c.split(';'))
    }
  }, [selectedOpportunity?.Modules__c])

  const handleUpdateOpportunity = async () => {
    try {
      if (currentToken) {
        const updatedData = {
          StageName: stage || undefined, // Convert null to undefined
          CloseDate: closeDate || undefined, // Convert null to undefined
          Amount_of_Users__c: amountOfUsers,
          Modules__c: modules, // Use array for Modules__c
        }

        await updateOpportunity(
          selectedOpportunity.Id,
          updatedData,
          currentToken,
          currentCustomer.customerId,
        )
      }
    } catch (error) {
      console.error('Error updating opportunity:', error)
    }
  }
  /// UPDATE OPPORTUNITY END///

  return (
    <div className="flex flex-col h-screen">
      <Sheet>
        {/* Top Bar */}
        <div className="flex justify-between items-center p-4 h-20 bg-white border-b">
          <h1 className="text-2xl font-bold">Opportunities</h1>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center gap-3"
            onClick={handleAddOpportunityClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            Add Opportunity
          </button>
        </div>

        {/* Opportunity Lists */}
        <div className="flex flex-col lg:flex-row h-[calc(100vh-5rem)] p-4 bg-slate-50 gap-4">
          <section
            id="open-opportunities"
            className="h-full bg-slate-100 w-[30rem] rounded-xl p-3 overflow-clip"
          >
            <div className="w-full bg-white rounded-xl h-12 p-3 flex items-center justify-between text-blue-400 mb-4">
              <span className="flex items-center gap-3">
                <div className="size-4 rounded-full bg-blue-400" />
                Open
              </span>
              <div className="bg-slate-200 h-full w-fit text-slate-600 text-xs rounded-lg px-3 py-1">
                {openOpportunities?.length} opportunities
              </div>
            </div>

            <div className="overflow-y-scroll h-[calc(100%-4rem)] ">
              {openOpportunities.length > 0 ? (
                openOpportunities.map((opportunity) => {
                  const currentStageIndex = stages.indexOf(
                    opportunity.StageName,
                  )
                  return (
                    <SheetTrigger key={opportunity.Id} className="w-full">
                      <div
                        className="bg-white rounded-xl border p-4 mb-2 cursor-pointer "
                        onClick={() => handleOpportunityClick(opportunity)}
                      >
                        <div className="flex justify-between items-center mb-4">
                          <h3 className="font-semibold text-left">
                            {opportunity.Name}
                          </h3>
                          <div className="text-xs px-3 py-1 rounded-full border w-32 min-w-max">
                            {opportunity.StageName}
                          </div>
                        </div>
                        <div className="flex gap-[2px]">
                          {stages.map((stage, index) => (
                            <span
                              key={stage}
                              className={`h-3 w-full ${
                                index <= currentStageIndex
                                  ? 'bg-sky-400'
                                  : 'bg-slate-200'
                              } ${
                                index === 0
                                  ? 'rounded-l-xl'
                                  : index === stages.length - 1
                                  ? 'rounded-r-xl'
                                  : ''
                              }`}
                            ></span>
                          ))}
                        </div>
                        <div className="mt-4 border-t pt-2 flex flex-col gap-2 items-start">
                          <span className="flex gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                              />
                            </svg>
                            {opportunity.Amount_of_Users__c}
                          </span>
                          <span className="flex gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
                              />
                            </svg>
                            {opportunity.Account_Name__c}
                          </span>
                          <p>Modules: {opportunity.Modules__c}</p>
                        </div>
                      </div>
                    </SheetTrigger>
                  )
                })
              ) : (
                <p className="text-gray-500 font-light text-xs">
                  No open opportunities
                </p>
              )}
            </div>
          </section>
          <section
            id="closed-opportunities"
            className="h-full bg-slate-100 w-[30rem]  rounded-xl p-3"
          >
            <div className="w-full bg-white rounded-xl h-12 p-3 flex items-center justify-between text-gray-400 mb-4">
              <span className="flex items-center gap-3">
                <div className="size-4 rounded-full bg-gray-400" />
                Closed
              </span>
              <div className="bg-slate-200 h-full w-fit text-slate-600 text-xs rounded-lg px-3 py-1">
                {closedOpportunities?.length} opportunities
              </div>
            </div>
            <div className="overflow-y-scroll h-[calc(100%-4rem)]">
              {closedOpportunities.length > 0 ? (
                closedOpportunities.map((opportunity) => {
                  const isClosedWon = opportunity.StageName === 'Closed Won'
                  const isClosedLost = opportunity.StageName === 'Closed Lost'
                  const currentStageIndex = stages.indexOf(
                    opportunity.StageName,
                  )

                  return (
                    <SheetTrigger key={opportunity.Id} className="w-full">
                      <div
                        className="bg-white rounded-xl border p-4 mb-2 cursor-pointer"
                        onClick={() => handleOpportunityClick(opportunity)}
                      >
                        <div className="flex justify-between items-center mb-4">
                          <h3 className="font-semibold text-left">
                            {opportunity.Name}
                          </h3>
                          <div className="text-xs px-3 py-1 rounded-full border w-32 min-w-max">
                            {opportunity.StageName}
                          </div>
                        </div>
                        <div className="flex gap-[2px]">
                          {stages.map((stage, index) => (
                            <span
                              key={stage}
                              className={`h-3 w-full ${
                                isClosedWon
                                  ? 'bg-green-400'
                                  : isClosedLost
                                  ? 'bg-rose-400'
                                  : index <= currentStageIndex
                                  ? 'bg-sky-400'
                                  : 'bg-slate-200'
                              } ${
                                index === 0
                                  ? 'rounded-l-xl'
                                  : index === stages.length - 1
                                  ? 'rounded-r-xl'
                                  : ''
                              }`}
                            ></span>
                          ))}
                        </div>
                        <div className="mt-4 border-t pt-2 flex flex-col items-start gap-2">
                          {/* <p>Stage: {opportunity.StageName}</p>
                    <p>Close Date: {opportunity.CloseDate}</p> */}
                          <span className="flex gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                              />
                            </svg>
                            {opportunity.Amount_of_Users__c}
                          </span>
                          <span className="flex gap-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
                              />
                            </svg>
                            {opportunity.Account_Name__c}
                          </span>
                          <p>Modules: {opportunity.Modules__c}</p>
                        </div>
                      </div>
                    </SheetTrigger>
                  )
                })
              ) : (
                <p className="text-gray-500 font-light text-xs">
                  No open opportunities
                </p>
              )}
            </div>
          </section>
        </div>

        {/* Add Opportunity Modal */}
        {showAddOpportunity && (
          <AddOpportunity
            onClose={() => setShowAddOpportunity(false)}
            onSuccess={fetchOpportunities}
          />
        )}

        <SheetContent side="right" className="w-[900px] !max-w-none">
          <SheetHeader className="h-full">
            <SheetTitle className="text-2xl">
              {selectedOpportunity?.Name}
            </SheetTitle>
            {/* <SheetDescription className="pt-12"> </SheetDescription> */}
            <div className="flex flex-col justify-between  h-full pt-10">
              <div>
                <div className="flex gap-[3px]">
                  {stages.map((stage, index) => {
                    const isClosedWon =
                      selectedOpportunity?.StageName === 'Closed Won'
                    const isClosedLost =
                      selectedOpportunity?.StageName === 'Closed Lost'
                    const currentStageIndex = stages.indexOf(
                      selectedOpportunity?.StageName,
                    )
                    const isActive = index === currentStageIndex

                    return (
                      <div
                        key={stage}
                        className={`h-8 w-full flex items-center justify-center text-xs font-medium ${
                          isClosedWon
                            ? 'bg-green-400 text-black'
                            : isClosedLost
                            ? 'bg-rose-400 text-white'
                            : isActive
                            ? 'bg-green-500 text-white'
                            : index < currentStageIndex
                            ? 'bg-green-300 text-green-700'
                            : 'bg-slate-300 text-slate-700/70'
                        } ${
                          index === 0
                            ? 'rounded-l-full'
                            : index === stages.length - 1
                            ? 'rounded-r-full'
                            : ''
                        }`}
                      >
                        {stage}
                      </div>
                    )
                  })}
                </div>
                <div className="pt-12">
                  <Label htmlFor="stage-select">Stage</Label>
                  <Select value={stage || undefined} onValueChange={setStage}>
                    <SelectTrigger className="w-1/2">
                      <SelectValue placeholder="Update stage" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Prospecting">Prospecting</SelectItem>
                      <SelectItem value="Qualification">
                        Qualification
                      </SelectItem>
                      <SelectItem value="Value Proposition">
                        Value Proposition
                      </SelectItem>
                      <SelectItem value="Proposal/Price Quote">
                        Proposal/Price Quote
                      </SelectItem>
                      <SelectItem value="Negotiation/Review">
                        Negotiation/Review
                      </SelectItem>
                      <SelectItem value="Closed Won">Closed Won</SelectItem>
                      <SelectItem value="Closed Lost">Closed Lost</SelectItem>
                    </SelectContent>
                  </Select>

                  <div className="flex gap-2 mt-6">
                    <span className="flex flex-col gap-1 w-full">
                      <Label htmlFor="amountOfUsers">Amount of users</Label>
                      <Input
                        id="amountOfUsers"
                        className=""
                        type="number"
                        value={amountOfUsers || ''}
                        onChange={(e) =>
                          setAmountOfUsers(Number(e.target.value))
                        }
                        min={50}
                      />
                    </span>

                    <span className="flex flex-col gap-1 w-full">
                      <Label htmlFor="close-date">Close date</Label>
                      <input
                        id="close-date"
                        type="date"
                        value={closeDate || ''}
                        onChange={(e) => setCloseDate(e.target.value)}
                        className="rounded-md p-[6px] border"
                      />
                    </span>
                  </div>
                  <div className="flex flex-col justify-start items-start space-x-2 mt-4">
                    <p className="text-sm font-semibold mb-4">Modules:</p>
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        id="surveys"
                        checked={modules.includes('Surveys Module')}
                        onCheckedChange={() =>
                          handleModuleChange('Surveys Module')
                        }
                      />
                      <label
                        htmlFor="surveys"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        Surveys
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-4">
                <DialogClose className="self-start">
                  <button
                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={handleUpdateOpportunity}
                  >
                    Update Opportunity
                  </button>
                </DialogClose>

                <DialogClose className="mt-4 bg-gray-400 text-white px-4 py-2 rounded">
                  Close
                </DialogClose>
              </div>
            </div>
          </SheetHeader>
        </SheetContent>
      </Sheet>
    </div>
  )
}

export default Opportunities
