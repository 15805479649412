import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { useEffect, useState } from 'react';

import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
  onAuthStateChanged,
  signInWithCustomToken,
  User
} from 'firebase/auth';

import { getFirestore, getDoc, setDoc, addDoc, doc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase services
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// Function to log in with email and password
const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
    return { success: true };
  } catch (err: any) {
    return { success: false, message: err.message };
  }
};

// Function to log in with a custom token
const logInWithCustomToken = async (token: string) => {
  try {
    await signInWithCustomToken(auth, token);
  } catch (err: any) {
    alert(err.message);
  }
};

// Function to send a password reset email
const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return { success: true, message: 'Password reset link sent!' };
  } catch (err: any) {
    return { success: false, message: err.message };
  }
};

// Custom hook to get the current authenticated user
export function useAuth() {
  const [currentUser, setCurrentUser] = useState<any>(null);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => setCurrentUser(user));
    return unsub;
  }, []);

  return currentUser;
}

// Function to upload a file to Firebase Storage
export async function upload(file: File, currentUser: User | null, setLoading: (loading: boolean) => void) {
  if (!currentUser) {
    throw new Error('No user is logged in.'); // Or handle the error as appropriate
  }

  const fileRef = ref(storage, 'avatars/' + currentUser.uid + '.png');
  setLoading(true);
  try {
    await uploadBytes(fileRef, file);
    const photoUrl = await getDownloadURL(fileRef);
    await updateProfile(currentUser, { photoURL: photoUrl });
  } catch (error) {
    console.error('Error uploading file:', error);
  } finally {
    setLoading(false);
  }
}

// Export Firebase services and functions
export {
  auth,
  logInWithEmailAndPassword,
  logInWithCustomToken,
  sendPasswordReset,
  db,
  addDoc,
  setDoc,
  getDoc,
  doc,
};
